
.about{
    width: 100vw;
    background-image: linear-gradient(to top, transparent, transparent, transparent, #fff), url(../../assets/bgIMG.png);
    background-size: cover;
    background-position: center;
}

.abouts{
    width: 80vw;                   
    margin: 0 auto;
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    margin-top: 5rem;
    align-items: center;
    gap: 2rem;
    padding-bottom: 5rem;
}

.about__1{
    width: calc(100% / 3);
    width: 20rem;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 1rem;    
    text-align: center;
}

.about__1 img{
 width: 10rem;
 background: gray;
 border-radius: 1rem;
 -webkit-border-radius: 1rem;
 -moz-border-radius: 1rem;
 -ms-border-radius: 1rem;
 -o-border-radius: 1rem;
}


.about__1 .sub__title{
    font-weight: bold;
    font-size: 1.3rem;
    color: #646566;
}

.about__1 .review{
    color: #646566;
    font-size: 16px;
}