.testimonials {
    background-image: linear-gradient(to bottom, transparent, transparent, transparent, #fff),
      url(../../assets/bgIMG.png);
    background-size: cover;
    background-position: center;
    width: 100vw;
    gap: 3rem;
    display: flex;
    flex-direction: column;
    align-items: center; /* Center align everything horizontally */
    padding: 3rem 0; /* Add some padding to the top and bottom */
  }
  
  .testimonial {
    width: 100%;
    max-width: 30rem; /* Limit maximum width */
    display: flex;
    justify-content: center;
    position: relative; /* Keep testimonials relative for absolute positioning */
  }
  
  .testimonial__details {
    width: 100%;
    max-width: 30rem;
    display: flex;
    flex-direction: column;
    /*Adjusttostackcontentvertically*/align-items: center;
    /*Centercontenthorizontally*/background: #fff;
    box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.1);
    /*Adjustbox-shadow*/padding: 2rem;
    border-radius: 1rem;
    transform: translateY(-50%);
    transition: 1s ease-in;
    -webkit-transition: 1s ease-in;
    -moz-transition: 1s ease-in;
    -ms-transition: 1s ease-in;
    -o-transition: 1s ease-in;
}
  
  .testimonial__details .testimonial__image {
    width: 4rem;
    height: 4rem;
    border: 2px solid #7e71d3; /* Increase border width */
    border-radius: 50%;
  }
  
  .testimonial__text {
    display: flex;
    flex-direction: column;
    gap: 1rem;
    text-align: center; /* Center text content */
  }
  
  .testimonial__text p {
    color: #454343;
    margin-top: 1rem;
    font-weight: 300;
    font-size: 12px;
  }
  
  .testimonial__text .details {
    display: flex;
    flex-direction: column;
    align-items: center; /* Center details horizontally */
  }
  
  .testimonial__text .details > span:first-child {
    font-weight: bold;
    color: #8a2be2;
  }
  
  .testimonial__text .details > span:last-child {
    font-size: 12px;
  }
  
  @media screen and (max-width: 768px) {
    .testimonial__details {
      max-width: 20rem;
    }
  }
  
  .chevrons {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 5rem;
    margin-top: 3rem; /* Adjust margin-top */
  }
  
  .chevrons img {
    width: 2rem;
    height: 2rem; /* Make the chevron buttons a consistent size */
    background: #fff;
    padding: 0.5rem; /* Reduce padding */
    box-shadow: 0 0 2px 0 rgba(0, 0, 0, 1); /* Adjust box-shadow */
    cursor: pointer;
    border-radius: 50%;
  }
  
  .chevrons img:hover {
    background: #9488de;
    color: #fff; /* Change fill to color */
    box-shadow: none;
  }
  