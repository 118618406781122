@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@400;500;600;700;800&display=swap');
*{
  padding: 0;
  margin: 0;
  box-sizing: border-box;
  scroll-behavior: smooth;
  font-family: 'Poppins', sans-serif;
  border: none;
  outline: none;
  
}

.container{
  width: 94vw;
}
.app {
  overflow: hidden;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  appearance: unset;
}

a{
  text-decoration: none;
}

li{
  list-style: none;
}

.title{
  text-align: center;
  font-size: 1.2rem;
}

.title span{
  color: slateblue;
}

/* Modify the width of the scrollbar */
::-webkit-scrollbar {
  width: 8px;
}

/* Style the scrollbar track with a gradient */
::-webkit-scrollbar-track {
  background: linear-gradient(40deg, #58bf70, #fff , #7b70c1);
}

/* Style the scrollbar thumb */
::-webkit-scrollbar-thumb {
  background: linear-gradient(40deg, #ff5733, #f7dc6f);
  border-radius: 8px; /* Rounded corners for the thumb */
  height: 1rem;
}

@media screen and (max-width: 700px){
  .title{
    margin-top: 3rem;
    font-size: .8rem;
  }
}